import { Competition } from '../_app/types/firestore.ts'
import {
  getCompetitionTranslatedDescription,
  getCompetitionTranslatedName,
  useGetUserTimeLocale,
  useT,
  useUserLocale,
} from '../_app/translations/i18nUtils.tsx'
import { useRoutes } from '../_app/constants/routes.ts'
import { getCategoriesFromCompetitionRuns } from '../_app/utils/utils.ts'
import SizeChips from '../_app/components/sizeChips.tsx'
import Calendar from '../_app/components/icons/calendar.tsx'
import { deserializeCompetition, getIsUserParticipating } from '../_utils'
import { formatDate, isRegistrationOpen } from '../_app/utils'
import Check from '../_app/components/icons/check.tsx'
import Hourglass from '../_app/components/icons/hourglass.tsx'
import { getInitialSrc } from '../_app/components/roundedImage.tsx'
import Linked from '@components/linked.tsx'

type Props = {
  serializedCompetition: Competition
  dogIds: number[]
}

export default function CompetitionCard({ serializedCompetition, dogIds }: Props) {
  const competition = deserializeCompetition(serializedCompetition)
  const { id, dates, submitOpenDate, submitClose, locationName } = competition
  const sizes = getCategoriesFromCompetitionRuns(competition)
  const t = useT()
  const timeLocale = useGetUserTimeLocale()
  const locale = useUserLocale()

  const routes = useRoutes()
  const { isUserParticipating, isAwaitingApproval } = getIsUserParticipating(
    deserializeCompetition(serializedCompetition),
    dogIds,
  )

  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-1 flex-col">
        <div className="flex items-center justify-between">
          <a href={routes.competition(id)}>
            <div className="w-full h-[200px] overflow-hidden">
              <img
                src={getInitialSrc(competition.photo, 'original')}
                alt={getCompetitionTranslatedName(competition, locale)}
                className="w-full h-full object-cover sm:object-contain object-left"
              />
            </div>
          </a>
        </div>
        <div className="flex h-full flex-1 flex-col px-3">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center mt-8">
              <a href={routes.competition(id)}>
                <h2
                  className="m-0 pl-1 pt-1 pb-0 pr-0 text-xl font-medium underline"
                  data-cy="competitionCard.name">
                  {getCompetitionTranslatedName(competition, locale)}
                </h2>
              </a>
              <span>
                {isUserParticipating ? (
                  <Check
                    className="h-5 w-5 text-green-800"
                    dataCy="competition.youAreParticipating"
                  />
                ) : isAwaitingApproval ? (
                  <Hourglass fullClass="h-4 w-4 mt-1" />
                ) : null}
              </span>
            </div>

            <div className="flex flex-col text-right">
              <div className="flex gap-2 items-center text-gray-500 text-sm">
                <Calendar className="h-4 w-4" />
                <div className="flex gap-1 ">
                  {dates.map((date, index) => {
                    const isLast = index === dates.length - 1

                    return (
                      <span key={date.toString()}>
                        {formatDate(date.toDate(), isLast ? 'd MMMM yyyy' : 'd MMMM', timeLocale)}
                        {isLast ? '' : ', '}
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="align-center flex gap-2 mt-3">
            <SizeChips sizes={sizes} />
          </div>

          <h3 className="text-md font-normal pb-0">{locationName}</h3>

          {isRegistrationOpen(submitOpenDate.toDate(), submitClose.toDate()) ? (
            <span className="font-bold text-green-800">{t('home.registrationOpen')}</span>
          ) : null}

          <span className="line-clamp-6">
            <Linked text={getCompetitionTranslatedDescription(competition, locale)} />
          </span>
        </div>
        <div>
          <div className="mt-8 flex w-full justify-end">
            <a href={routes.competition(id)}>
              <button data-cy="competition.checkIt" className="btn">
                {t('home.checkIt')}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
